import { useState, useRef } from "react";
import { formOptions } from "../data/fromData";

export const hasAllRequiredKeysInList = (data, requiredKeys) => {
  return data.every((item) =>
    requiredKeys.every((key) => item.hasOwnProperty(key))
  );
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth is zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const updateTableHeight = (
  setHeight,
  minHeight = 500,
  percentageOfWindow = 0.5
) => {
  const windowHeight = window.innerHeight;
  const dynamicHeight = Math.max(minHeight, windowHeight * percentageOfWindow); // Minimum height or 50% of window height
  setHeight(dynamicHeight);
};

export const parseDate = (dateString) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day); // Month is 0-indexed
};

export const filterInventoryByExpiry = (inventory) => {
  const currentDate = new Date();
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(currentDate.getMonth() + 3);

  return inventory.filter((inv) => {
    const expiryDate = parseDate(inv.expiry);
    return expiryDate > currentDate && expiryDate <= threeMonthsFromNow;
  });
};

export const isLessThanTwentyPercent = (givenQuantity, totalQuantity) => {
  const twentyPercent = totalQuantity * 0.2;
  return Number(givenQuantity) <= twentyPercent;
};

export const filterItemsOlderThanSixMonths = (medicine) => {
  const { stockEnteredDate, unitsInStock, deadStockQuantityCheck } = medicine;
  const salePercentage =
    ((deadStockQuantityCheck - unitsInStock) / deadStockQuantityCheck) * 100;
  const currentDate = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  return (
    stockEnteredDate < sixMonthsAgo.valueOf() &&
    1 <= salePercentage &&
    salePercentage <= 20
  );
};

export const getUndefinded = (data) => {
  let undefindedValues = [];
  for (const key in data) {
    if (
      data[key] === undefined ||
      data[key] === "undefined" ||
      data[key] === ""
    )
      undefindedValues.push(key);
  }
  return undefindedValues;
};

export const hasMoreThanTwoUniqueTrueKeys = (obj) => {
  // Get all keys that have a value of true
  const trueKeys = Object.keys(obj).filter((key) => obj[key]);

  // Use a Set to ensure unique keys
  const uniqueTrueKeys = new Set(trueKeys);

  // Check if the number of unique true keys is greater than two
  return uniqueTrueKeys.size > 2;
};
export const filterListByIds = (list, keyObj) => {
  // Get the IDs from keyObj that have true values
  const validIds = Object.keys(keyObj).filter((key) => keyObj[key]);

  // Filter the list based on these IDs
  return list.filter((item) => validIds.includes(item.id));
};
export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const prepareFormDataUsingSchema = (
  schema,
  selectedValues,
  pharmacological_names = [],
  brand_names = []
) => {
  // Find the pharmacologicalName and brandName IDs based on selected values
  const pName = pharmacological_names.find(
    ({ name }) => name === selectedValues.pharmacologicalName
  );
  const pName_id = pName ? pName.id : null;

  const bName = brand_names.find(
    ({ name }) => name === selectedValues.brandName
  );
  const formObj = formOptions.find(
    ({ value }) => value?.toLowerCase() === selectedValues?.form?.toLowerCase()
  );
  const bName_id = bName ? bName.id : null;

  const formData = {};

  schema.forEach((field) => {
    const { name, type } = field;

    if (name === "pharmacologicalName" && pName_id) {
      // Set the pharmacologicalName value based on pName_id
      formData[name] = {
        value: pName_id,
        label: pName.name, // Set label to the name
      };
    } else if (name === "brandName" && bName_id) {
      // Set the brandName value based on bName_id
      formData[name] = {
        value: bName_id,
        label: bName.name, // Set label to the name
      };
    } else if (name === "form") {
      // const formObj = formOptions.find(({value}) => value === schema[name]);
      formData[name] = {
        value: formObj.value,
        label: formObj.label, // Set label to the name
      };
    } else if (type === "select") {
      // Handle other select fields
      formData[name] = selectedValues[name]
        ? selectedValues[name]
        : { value: "", label: "Select" };
    } else {
      // For other types (e.g., text/number fields)
      formData[name] = selectedValues[name] || ""; // Set value or empty string
    }
  });

  return formData;
};

export const logWithFile = (...args) => {
  // Create a new Error object to capture the stack trace
  const stack = new Error().stack;

  // Extract the relevant line of the stack trace (the one that called logWithFile)
  const stackLine = stack.split("\n")[2] || ""; // Line 2 in the stack usually contains caller information

  // Extract the file name and line number from the stack trace
  const match = stackLine.match(/\((.*):(\d+):(\d+)\)/);

  let fileInfo = "";
  if (match) {
    const filePath = match[1];
    const lineNumber = match[2];
    const columnNumber = match[3];

    // Extract just the file name from the file path
    const fileName = filePath.split("/").pop();

    // Format file info
    fileInfo = `${fileName}:${lineNumber}:${columnNumber}`;
  }

  // Log the file name and line number along with the actual log message
  console.log(`[${fileInfo}]`, ...args);
};

export const addRupeeSymbol = (amount) => {
  if (amount === undefined || amount === null || isNaN(amount)) return ""; // Handle edge cases

  // Convert the input into a number and format it using `toLocaleString` with Indian currency formatting
  const formattedAmount = Number(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2, // Set to 2 decimal places
  });

  return formattedAmount; // Returns formatted value with rupee symbol
};

export const generatePRUid = (name, count = 0) => {
  let vendorName = name?.slice(0, 3)
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const fromattedNumber = String(count + 1).padStart(3, "0");
  return `PR-${vendorName}-${year}${month}${day}-${fromattedNumber}`;
};

export const useDraggable = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const elementRef = useRef(null);

  const handleDrag = (e, ui) => {
    setPosition((prev) => ({
      x: prev.x + ui.deltaX,
      y: prev.y + ui.deltaY,
    }));
  };

  const handleStop = () => {
    if (elementRef.current) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const elementRect = elementRef.current.getBoundingClientRect();
      const elementWidth = elementRect.width;
      const elementHeight = elementRect.height;

      let finalX = position.x;
      let finalY = position.y;

      // Check if the element is out of bounds
      const isOutOfBounds =
        elementRect.left < 0 ||
        elementRect.right > windowWidth ||
        elementRect.top < 0 ||
        elementRect.bottom > windowHeight;

      // Snap to edges if out of bounds
      if (isOutOfBounds) {
        if (elementRect.left < 0) {
          finalX = 0; // Snap to left edge
        } else if (elementRect.right > windowWidth) {
          finalX = windowWidth - elementWidth; // Snap to right edge
        }
        if (elementRect.top < 0) {
          finalY = 0; // Snap to top edge
        } else if (elementRect.bottom > windowHeight) {
          finalY = windowHeight - elementHeight; // Snap to bottom edge
        }
      }

      setPosition({ x: finalX, y: finalY });
    }
  };

  return { position, setPosition, elementRef, handleDrag, handleStop };
};

export function countTodayEntries(list = []) {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
  console.log(list.filter(item => item.date === today).length, 'count')

  return list.filter(item => item.date === today).length;
}

// utils/Utils.js
export const handleCommaDelimiterChange = (event, setValue, name) => {
  if (name !== "quantity") return; // Only apply for "quantity" field

  let value = event.target.value;

  // Remove all non-numeric characters except commas
  value = value.replace(/[^0-9]/g, "");

  // If the value is less than or equal to 3 digits, just return the value without commas
  if (value.length <= 3) {
    setValue(name, value); // Update the value without any comma formatting
    return;
  }

  // Apply the Indian numbering system format
  let firstPart = value.slice(0, value.length - 3); // First part: All digits except the last 3
  let lastPart = value.slice(value.length - 3); // Last 3 digits

  // Add commas for the Indian numbering system
  firstPart = firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

  // Combine the formatted first part and the last part
  value = firstPart + "," + lastPart;

  setValue(name, value); // Update the value in the form
};
