import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  writeBatch,
  query,
  where,
  documentId,
} from "firebase/firestore";
import { db } from "../../firebase";

// Fetch all documents
export const fetchData = async (collectionName) => {
  try {
    const colRef = collection(db, collectionName);
    const querySnapshot = await getDocs(colRef);
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // console.log("Data fetched in fetchData:", data); // Ensure data is logged correctly here
    return data;
  } catch (error) {
    console.error("Error fetching data in fetchData: ", error);
    throw new Error(error.message);
  }
};

// Create a new document
export const createData = async (collectionName, newData) => {
  const colRef = collection(db, collectionName);
  const docRef = await addDoc(colRef, newData);
  if (docRef && docRef.id) {
    console.log(docRef.id, docRef, "docRef");
    return docRef.id;
  }
};

// Update a document
export const updateData = async (collectionName, { id, updatedData }) => {
  console.log(collectionName, { id, updatedData }, "in usefire");
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, updatedData);
};

// Bulk update documents
// Bulk update data hook
export const bulkUpdateData = async (collectionName, updates) => {
  try {
    const batch = writeBatch(db);
    updates.forEach((updateObj) => {
      const { id, ...updatedData } = updateObj; // Extract the id and the fields to update
      console.log(id, updatedData, "firebaseOp");
      if (!id || Object.keys(updatedData).length === 0) {
        throw new Error(
          `Invalid update format: { id: ${id}, updatedData: ${JSON.stringify(
            updatedData
          )} }`
        );
      }

      const docRef = doc(db, collectionName, id); // Reference to the document
      batch.update(docRef, updatedData); // Perform batch update with the remaining fields
    });

    await batch.commit(); // Commit the batch operation
    console.log("Batch update successfully committed");
    return updates;
  } catch (error) {
    console.error("Error in bulkUpdateData:", error);
    throw new Error(error.message);
  }
};

// Delete a document
export const deleteData = async (collectionName, id) => {
  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);
};

export const getDocsByQuery = async (collectionName, conditions = []) => {
  console.log(collectionName, conditions, "firebaseOp getDocsByQuery");
  try {
    const colRef = collection(db, collectionName);
    let firestoreQuery = query(colRef);

    conditions.forEach((condition) => {
      firestoreQuery = query(
        firestoreQuery,
        where(condition.field, condition.operator, condition.value)
      );
    });

    const querySnapshot = await getDocs(firestoreQuery);
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data with conditions: ", error);
    throw new Error(error.message);
  }
};

export const bulkCreateData = async (collectionName, data) => {
  console.log(collectionName, data, "fire");
  const collectionRef = collection(db, collectionName);
  const batch = [];

  data.forEach((doc) => {
    const docRef = addDoc(collectionRef, doc);
    batch.push(docRef, doc);
  });

  await Promise.all(batch);
  return data;
};

export const fetchDocsByIds = async (collectionName, medicineIds) => {
  if (!medicineIds || medicineIds.length === 0) {
    throw new Error("No medicine IDs provided");
  }

  const collectionRef = collection(db, collectionName);
  let data = [];

  // Firestore allows up to 10 values in an `in` query
  const batchSize = 10;

  for (let i = 0; i < medicineIds.length; i += batchSize) {
    const batchIds = medicineIds.slice(i, i + batchSize);

    // Use the `in` operator for batch queries
    const q = query(collectionRef, where("medicineId", "in", batchIds));

    try {
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
    } catch (error) {
      console.error(
        `Error fetching documents for medicine IDs: ${batchIds.join(", ")}`,
        error
      );
      throw new Error(`Failed to fetch inventory documents: ${error.message}`);
    }
  }
console.log(data, "data in fr")
  return data;
};

export const getDocumentsSize = async (collectionName, conditions = []) => {
  try {
    const colRef = collection(db, collectionName);
    let firestoreQuery = query(colRef);

    conditions.forEach((condition) => {
      firestoreQuery = query(
        firestoreQuery,
        where(condition.field, condition.operator, condition.value)
      );
    });

    const querySnapshot = await getDocs(firestoreQuery);
    console.log(querySnapshot, "querySnapshot")
    return querySnapshot.size;
    // const data = querySnapshot.docs.map((doc) => ({
    //   id: doc.id,
    //   ...doc.data(),
    // }));
    // console.log(data);
    // return data;
  } catch (error) {
    console.error(`Error fetching data with ${collectionName}: `, error);
    throw new Error(error.message);
  }
};
